import { useEffect, useMemo, useState } from 'react';

import { CampaignName } from 'src/common/enums/campaigns';
import { Campaign } from 'src/common/interfaces/campaigns';
import { useGetUserQuery } from 'src/common/slices';

import { useGetCampaignsQuery } from '../campaigns.slice';

export type UseCampaignHookResult = {
  campaign: Campaign | null;
  shouldPromote: boolean;
};

export const useCampaign = (name: CampaignName): UseCampaignHookResult => {
  const [timeIsValid, setTimeIsValid] = useState(false);

  const { data: userDataResponse } = useGetUserQuery();
  const { data: campaignsResponse } = useGetCampaignsQuery();

  const campaign = useMemo<Campaign | null>(() => {
    if (!campaignsResponse?.data) {
      return null;
    }

    const data = campaignsResponse.data.find((campaignData) => campaignData.name === name);
    if (!data) {
      return null;
    }

    return {
      name: data.name,
      amount: data.amount,
      startTime: new Date(data.startDate).getTime(),
      endTime: new Date(data.endDate).getTime(),
    };
  }, [campaignsResponse, name]);

  useEffect(() => {
    if (!campaign) return;

    const checkValidity = () => {
      const now = Date.now();
      setTimeIsValid(campaign.endTime > now);
    };

    const intervalId = setInterval(checkValidity, 1000);
    checkValidity();

    return () => {
      clearInterval(intervalId);
    };
  }, [campaign]);

  return useMemo<UseCampaignHookResult>(() => {
    if (!campaign) {
      return { campaign: null, shouldPromote: false };
    }

    if (!userDataResponse?.data?.user) {
      return { campaign, shouldPromote: timeIsValid };
    }

    // TODO: campaign: revert new users check
    // const { user } = userDataResponse.data;
    // const isNewSignup = Date.now() - new Date(user.dateSignUp).getTime() < 1000 * 60 * 60 * 24;

    return { campaign, shouldPromote: timeIsValid };
    // return { campaign, shouldPromote: !isNewSignup && timeIsValid };
  }, [campaign, timeIsValid, userDataResponse?.data]);
};
