import { apiSlice } from 'src/features/api/api-slice';
import { ApiResponse } from 'src/common/interfaces';
import { CampaignDTO } from 'src/common/interfaces/campaigns';
import { RTKQueryTag } from 'src/common/constants';

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCampaigns: builder.query<ApiResponse<CampaignDTO[]>, void>({
      query: () => ({
        url: 'campaigns',
        method: 'GET',
      }),
      providesTags: [RTKQueryTag.Campaigns],
    }),
  }),
});

export const { useGetCampaignsQuery } = extendedApiSlice;
