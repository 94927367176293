import { FC, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ThemeProvider, useTheme } from 'styled-components';
import { darkColors, Modal } from '@tryhackme/thm-ui-components';
import { ReactComponent as ModalImg } from 'src/images/svgs/fireship/fireship-image.svg';
import { useAppDispatch, useAppSelector } from 'src/app/hooks';
import { useCampaign } from 'src/features/campaigns/hooks/use-campaign';
import { CampaignName } from 'src/common/enums/campaigns';
import { useCampaignStorage } from 'src/features/campaigns/hooks/use-campaign-storage';
import { useCampaignCta } from 'src/features/campaigns/hooks/use-campaign-cta';
import { useGetUserQuery } from 'src/common/slices';
import { ModalType } from 'src/common/enums';
import { setCurrentModal } from 'src/app/reducers/modal-states.reducer';
import { useCampaignSession } from '../../hooks/use-campaign-session';
import { StyledContent, Styledtag, StyledWrapper, StyledfFireshipRedirectButton } from './fireship-modal.styles';
export const FireshipCampaignModal: FC = () => {
  const dispatch = useAppDispatch();
  const {
    currentModal
  } = useAppSelector(state => state.modalStates);
  const modalIsShown = currentModal === ModalType.FIRESHIP_CAMPAIGN;
  const {
    campaign
  } = useCampaign(CampaignName.FIRESHIP_2025);
  const {
    isSeen,
    markAsSeen,
    reset
  } = useCampaignStorage(campaign);
  const isInFireshipSession = useCampaignSession(CampaignName.FIRESHIP_2025);
  const {
    data: userDataResponse
  } = useGetUserQuery();
  const [ctaText, urlToNavigate] = useCampaignCta();
  const [prevAuthState, setPrevAuthState] = useState(!!userDataResponse?.data);
  const theme = useTheme();
  useEffect(() => {
    const isLoggedIn = !!userDataResponse?.data;
    const wasLoggedIn = !!prevAuthState;
    if (isLoggedIn !== wasLoggedIn) {
      reset();
      setPrevAuthState(isLoggedIn);
    }
  }, [userDataResponse?.data, prevAuthState, reset]);
  const onClose = () => {
    markAsSeen();
    dispatch(setCurrentModal(null));
  };
  const shouldShow = !!isInFireshipSession && !!campaign && (userDataResponse?.data ? !isSeen : true);
  const url = userDataResponse?.data ? urlToNavigate : '/signup?source=fireship';
  return shouldShow ? <ThemeProvider theme={{
    ...theme,
    colors: darkColors
  }}>
      <Modal padding="0" open={modalIsShown} onOpenChange={onClose}>
        <StyledWrapper>
          <StyledContent>
            <ModalImg />
            <Styledtag>Fireship Viewers Exclusive</Styledtag>
            <h2>Welcome to TryHackMe!</h2>
            <p>
              You&apos;ve unlocked a special offer! <br />
              {campaign?.amount === 30 ? <>
                  You&apos;ve <span>scored 30%</span> off premium &ndash; 20% for Fireship fans, plus a 10% flash bonus.
                  Grab it before it&apos;s gone!
                </> : <>
                  Fireship viewers get <span>20% off</span> our premium subscription!
                </>}
            </p>

            <StyledfFireshipRedirectButton as={Link} to={url} variant="solid" color="primary" onClick={onClose}>
              {ctaText}
            </StyledfFireshipRedirectButton>
          </StyledContent>
        </StyledWrapper>
      </Modal>
    </ThemeProvider> : null;
};