import { useCallback, useEffect, useMemo } from 'react';

import { Campaign, StoredCampaign } from 'src/common/interfaces/campaigns';

const storageKey = 'campaignsSeen';

const getSeenCampaigns = (): StoredCampaign[] => {
  try {
    const stored = localStorage.getItem(storageKey) || '';
    return (JSON.parse(stored) || []) as StoredCampaign[];
  } catch {
    return [];
  }
};

const storeCampaigns = (campaigns: StoredCampaign[]) => {
  localStorage.setItem(storageKey, JSON.stringify(campaigns));
};

export const useCampaignStorage = (current: Campaign | null) => {
  // Clean up obsolete campaigns from storage
  useEffect(() => {
    const campaignsSeen = getSeenCampaigns();
    const cleanedUp = campaignsSeen.filter((storedCampaign) => storedCampaign.endTime >= Date.now());

    storeCampaigns(cleanedUp);
  }, []);

  const markAsSeen = useCallback(() => {
    if (!current) return;

    const campaignsSeen = getSeenCampaigns();
    const alreadySeen = campaignsSeen.some((c) => c.name === current.name);
    if (alreadySeen) {
      return;
    }

    storeCampaigns([
      ...campaignsSeen,
      {
        name: current.name,
        endTime: current.endTime,
      },
    ]);
  }, [current]);

  const isSeen = useMemo(() => {
    if (!current) return false;
    const seen = getSeenCampaigns();
    return seen.some((an) => an.name === current.name);
  }, [current]);

  const reset = () => {
    if (!current) return;

    const campaignsSeen = getSeenCampaigns();
    storeCampaigns(campaignsSeen.filter((an) => an.name !== current.name));
  };

  return { isSeen, markAsSeen, reset };
};
