import styled from 'styled-components';

import { Price } from 'src/features/pricing/components/price';

export const StyledMessage = styled.div`
  font-weight: ${({ theme }) => theme.fonts.weight.bold};
  font-size: 2.4rem;
  margin-bottom: 1rem;
  text-align: center;
`;

export const StyledOldPrice = styled(Price)`
  font-weight: ${({ theme }) => theme.fonts.weight.regular};
  text-decoration: line-through;
`;
