import styled from 'styled-components';

export const StyledTitle = styled.h2<{ $shouldShowEmptyState?: boolean }>`
  font-family: ${({ theme }) => theme.fonts.familySansPro};
  font-size: 2.4rem;
  font-weight: ${({ theme }) => theme.fonts.weight.bold};
  margin-bottom: ${({ $shouldShowEmptyState }) => ($shouldShowEmptyState ? '2.4rem !important' : 'none')};

  &[data-type='league']::first-letter {
    text-transform: uppercase;
  }
`;
