import { FC } from 'react';
import { Price } from 'src/features/pricing/components/price';
import { StyledCenteredContent, StyledText } from '../../campaign-modal.styles';
import { StyledMessage, StyledOldPrice } from './discount-message.styles';
import { DiscountMessageProps } from './discount-message.types';
export const DiscountMessage: FC<DiscountMessageProps> = ({
  price,
  discountedPrice,
  symbol
}) => <StyledCenteredContent data-sentry-element="StyledCenteredContent" data-sentry-component="DiscountMessage" data-sentry-source-file="discount-message.tsx">
    <StyledMessage data-sentry-element="StyledMessage" data-sentry-source-file="discount-message.tsx">
      Subscribe for{' '}
      <StyledOldPrice price={price} symbol={symbol} data-sentry-element="StyledOldPrice" data-sentry-source-file="discount-message.tsx">
        {(int, fraction) => int + fraction}
      </StyledOldPrice>{' '}
      <Price price={discountedPrice} symbol={symbol} data-sentry-element="Price" data-sentry-source-file="discount-message.tsx">
        {(int, fraction) => int + fraction}
      </Price>
      /year
    </StyledMessage>
    <StyledText data-sentry-element="StyledText" data-sentry-source-file="discount-message.tsx">
      Join <span>100,000s+</span> of professionals who have advanced their security careers.
    </StyledText>
  </StyledCenteredContent>;