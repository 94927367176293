import { secondaryColors } from '@tryhackme/thm-ui-components';
import styled from 'styled-components';

export const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4.6rem 1.8rem 3rem;
  text-align: center;
  background-color: ${secondaryColors.black};
  background-image: ${({ theme }) => `
    radial-gradient(350px 180px at 50% 105%, ${theme.colors.info.main} -200%, transparent 100%);
  `};

  @media screen and (min-width: ${({ theme }) => theme.sizes.md}) {
    padding: 4.6rem 4.6rem 3rem;
  }
`;

export const StyledFadeRoot = styled.div<{ $fade: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: opacity 0.2s ease-out;
  opacity: ${({ $fade }) => ($fade ? '0' : '1')};
`;

export const StyledHeading = styled.span`
  font-size: ${({ theme }) => theme.fonts.sizes.subTitle};
  color: ${({ theme }) => theme.colors.text.main};
  font-weight: 800;
  margin-bottom: 1.6rem;

  @media screen and (min-width: ${({ theme }) => theme.sizes.lg}) {
    font-size: 5.4rem;
  }
`;

export const StyledCongrats = styled.p`
  font-size: ${({ theme }) => theme.fonts.sizes.subTitle};
  font-weight: ${({ theme }) => theme.fonts.weight.bold};
  line-height: ${({ theme }) => theme.fonts.sizes.title};
  margin-bottom: 2.4rem;

  span {
    color: ${({ theme }) => theme.colors.primary.main};
  }
`;

export const StyledSubtitle = styled.p`
  font-size: ${({ theme }) => theme.fonts.sizes.default};
  margin-bottom: 2.4rem;

  @media screen and (min-width: ${({ theme }) => theme.sizes.lg}) {
    font-size: 2.4rem;
  }
`;

export const StyledBoxWrapper = styled.div`
  width: 15rem;
  height: 22rem;
  margin: 2.4rem 0;
`;

export const StyledBoxImage = styled.img`
  max-width: 100%;
  max-height: 100%;
`;

export const StyledDiscountMessage = styled.div`
  margin-bottom: 2.4rem;
`;
