import { CampaignName } from 'src/common/enums/campaigns';
import { CampaignConfig } from 'src/common/interfaces/campaigns';

import { checkMatch } from './helpers';

export const topCampaignBar: CampaignConfig = {
  name: CampaignName.FLASH_SALE_MYSTERY_DISCOUNT_BOX,
  pathsToExclude: [
    '/onboarding/*',
    '/why-subscribe',
    '/pricing',
    '/welcome',
    '/payment/pending',
    '/feedback/subscription',
    '/room/*',
    '/echo',
    '/path/outline/*',
    '/recapme/*',
    '/login/*',
    '/certification/*',
  ],
  canShow(pathname, user) {
    const isPremium = user?.user?.isPremium;
    const isOnManageSub = checkMatch(['/manage-account/subscription'], pathname).doesMatch;

    return isPremium ? !isOnManageSub : true;
  },
};

export const embeddedCampaignBar: CampaignConfig = {
  name: CampaignName.FLASH_SALE_MYSTERY_DISCOUNT_BOX,
  pathsToInclude: ['/why-subscribe'],
};

export const currentCampaignModal: CampaignConfig = {
  name: CampaignName.FLASH_SALE_MYSTERY_DISCOUNT_BOX,
  pathsToInclude: ['/dashboard'],
};
