import styled from 'styled-components';

export const StyledTimer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;

  @media screen and (min-width: ${({ theme }) => theme.sizes.md}) {
    gap: 1.6rem;
    flex-direction: row;
  }
`;

export const StyledMessage = styled.span`
  font-size: ${({ theme }) => theme.fonts.sizes.default};

  @media screen and (min-width: ${({ theme }) => theme.sizes.md}) {
    gap: 2.4rem;
    font-size: 2.4rem;
  }
`;

export const StyledCountdown = styled.div`
  display: flex;
  align-items: center;
  gap: 0.4rem;

  @media screen and (min-width: ${({ theme }) => theme.sizes.md}) {
    gap: 0.8rem;
  }
`;

export const StyledClockSeparator = styled.div<{ $vertical?: boolean; $dark?: boolean }>`
  font-size: ${({ theme, $vertical }) => ($vertical ? '3rem' : theme.fonts.sizes.default)};
  margin-top: ${({ $vertical }) => ($vertical ? '-3.1rem' : 'initial')};
  color: ${({ theme, $dark }) => ($dark ? theme.colors.grey[100] : theme.colors.black)};

  @media screen and (min-width: ${({ theme }) => theme.sizes.md}) {
    margin-top: ${({ $vertical }) => ($vertical ? '-2rem' : 'initial')};
  }
`;

export const StyledTimeblock = styled.div<{ $vertical?: boolean; $dark?: boolean }>`
  color: ${({ theme }) => theme.colors.black};
  font-size: ${({ theme }) => theme.fonts.sizes.default};
  border-radius: 0.5rem;
  padding: 0.2rem 0.6rem;

  display: flex;
  gap: ${({ $vertical }) => ($vertical ? '.4rem' : '.2rem')};
  flex-direction: ${({ $vertical }) => ($vertical ? 'column' : 'row')};

  align-items: ${({ $vertical }) => ($vertical ? 'center' : 'flex-end')};
  color: ${({ theme, $dark }) => ($dark ? theme.colors.grey[100] : theme.colors.black)};
  background-color: ${({ theme, $dark }) => ($dark ? 'auto' : theme.colors.grey[100])};

  @media screen and (min-width: ${({ theme }) => theme.sizes.md}) {
    padding: ${({ $dark }) => ($dark ? '0.2rem 0.6rem' : '0.6rem')};
    height: ${({ $vertical, $dark }) => ($vertical || $dark ? 'auto' : '3.4rem')};
  }
`;

export const StyledTimeblockValue = styled.span<{ $vertical?: boolean; $dark?: boolean }>`
  font-size: ${({ theme, $vertical }) => ($vertical ? theme.fonts.sizes.subTitle : theme.fonts.sizes.medium)};
  line-height: ${({ theme }) => theme.fonts.lineHeight.default};
  font-weight: ${({ theme }) => theme.fonts.weight.bold};

  @media screen and (min-width: ${({ theme }) => theme.sizes.md}) {
    font-size: ${({ theme }) => theme.fonts.sizes.subTitle};
  }
`;

export const StyledUnit = styled.span<{ $vertical?: boolean; $dark?: boolean }>`
  font-size: ${({ theme }) => theme.fonts.sizes.tiny};
  color: ${({ theme, $dark }) => ($dark ? theme.colors.primary.main : 'auto')};
  line-height: 2rem;

  @media screen and (min-width: ${({ theme }) => theme.sizes.md}) {
    font-size: ${({ theme, $vertical }) => ($vertical ? theme.fonts.sizes.tiny : theme.fonts.sizes.default)};
    line-height: ${({ theme }) => theme.fonts.sizes.tiny};
  }
`;
