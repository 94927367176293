import { FC } from 'react';
import { Link } from 'react-router-dom';
import { ThemeProvider, useTheme } from 'styled-components';
import { darkColors, StyledButton } from '@tryhackme/thm-ui-components';
import { CampaignComponentProps } from 'src/common/interfaces/campaigns';
import { useTrackEventMutation } from 'src/common/slices';
import { CampaignName } from 'src/common/enums/campaigns';
import { ModalType } from 'src/common/enums';
import { useAppDispatch, useAppSelector } from 'src/app/hooks';
import mysteryBox from 'src/images/pngs/campaigns/mystery-box-closed.png';
import { setCurrentModal } from 'src/app/reducers/modal-states.reducer';
import { Countdown } from '../countdown';
import { useCampaign } from '../../hooks/use-campaign';
import { useCampaignCta } from '../../hooks/use-campaign-cta';
import { useCampaignSession } from '../../hooks/use-campaign-session';
import { CampaignBarProps } from './campaign-bar.types';
import { StyledBar, StyledBoxImage, StyledBoxWrapper, StyledHeading, StyledHighlight, StyledText, StyledTimerMessage, StyledWrapRow } from './campaign-bar.styles';
export const CampaignBar: FC<CampaignComponentProps & CampaignBarProps> = ({
  name,
  thin
}) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const revealed = useAppSelector(state => state.campaign.mysteryBoxRevealed);
  const {
    campaign,
    shouldPromote
  } = useCampaign(name);
  const [ctaText, urlToNavigate] = useCampaignCta();
  const [trackEvent] = useTrackEventMutation();
  const isInFireshipSession = useCampaignSession(CampaignName.FIRESHIP_2025);
  const openMysteryBoxModal = () => dispatch(setCurrentModal(ModalType.MYSTERY_BOX_CAMPAIGN));
  const onCtaClick = () => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    trackEvent({
      event: 'click-subscribe-now',
      properties: {
        'clicked-on': 'top-banner'
      }
    });
  };
  const renderOnTopBar = (amount: number) => revealed ? <StyledText>
        MYSTERY BOX DISCOUNT: <StyledHighlight>{amount}% off</StyledHighlight> annual subscriptions!
      </StyledText> : <StyledBoxWrapper>
        <StyledHeading $thin={!!thin}>WHAT’S IN THE BOX?</StyledHeading>
        <StyledBoxImage src={mysteryBox} />
        <StyledButton variant="solid" color="primary" onClick={openMysteryBoxModal}>
          Reveal discount
        </StyledButton>
      </StyledBoxWrapper>;
  return campaign && shouldPromote && !isInFireshipSession ? <ThemeProvider theme={{
    ...theme,
    colors: darkColors
  }}>
      <StyledBar $thin={!!thin} data-testid="campaign-bar">
        <StyledWrapRow>
          {thin ? <StyledTimerMessage>Offer ends in:</StyledTimerMessage> : renderOnTopBar(campaign.amount)}

          {!thin && revealed && <StyledButton as={Link} to={urlToNavigate} variant="solid" color="primary" onClick={onCtaClick}>
              {ctaText}
            </StyledButton>}
        </StyledWrapRow>
        {/* <StyledTimerMessage>Ends in:</StyledTimerMessage> */}
        <Countdown includeDays vertical dark startTime={campaign.startTime} endTime={campaign.endTime} />
      </StyledBar>
    </ThemeProvider> : null;
};