import { ReactElement } from 'react';

import { CampaignName } from 'src/common/enums/campaigns';
import { CampaignConfig } from 'src/common/interfaces/campaigns';

export interface CampaignsProps {
  config: CampaignConfig;
  children: (name: CampaignName) => ReactElement;
}

export const CAMPAIGN_SESSION_STORAGE_KEY = 'thm_campaign_session';
