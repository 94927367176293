import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface CampaignState {
  mysteryBoxRevealed: boolean;
}

export const MYSTERY_BOX_CAMPAIGN_KEY = 'thm_mystery_box_campaign';

const initialState: CampaignState = {
  mysteryBoxRevealed: sessionStorage.getItem(MYSTERY_BOX_CAMPAIGN_KEY) === 'revealed',
};

const campaignSlice = createSlice({
  name: 'campaign',
  initialState,
  reducers: {
    revealMysteryBox(state, action: PayloadAction<boolean>) {
      state.mysteryBoxRevealed = action.payload;

      if (action.payload) {
        sessionStorage.setItem(MYSTERY_BOX_CAMPAIGN_KEY, 'revealed');
      } else {
        sessionStorage.removeItem(MYSTERY_BOX_CAMPAIGN_KEY);
      }
    },
  },
});

export const { revealMysteryBox } = campaignSlice.actions;

const campaignReducer = campaignSlice.reducer;
export { campaignReducer };
