import { FC, useMemo } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { lightColors, StyledButton } from '@tryhackme/thm-ui-components';
import { ThemeProvider, useTheme } from 'styled-components';
import { checkIfShouldShow, checkMatch } from 'src/features/campaigns/helpers';
import { useGetUserQuery } from 'src/common/slices';
import { useCampaign } from 'src/features/campaigns/hooks/use-campaign';
import { CampaignName } from 'src/common/enums/campaigns';
import { useCampaignCta } from 'src/features/campaigns/hooks/use-campaign-cta';
import { useCampaignSession } from '../../hooks/use-campaign-session';
import { Countdown } from '../countdown';
import { StyledBar, StyledCountdown } from './fireship-bar.styles';
interface Props {
  overrideRules?: boolean;
  isCongratulationModal?: boolean;
}
const pathsToExclude = ['/onboarding/*', '/pricing', '/welcome', '/payment/pending', '/feedback/subscription', '/room/*', '/echo', '/path/outline/*', '/recapme/*', '/login/*', '/certification/*'];
const endTime = new Date('2025-04-11T00:00:00.000+01:00');
export const FireshipCampaignBar: FC<Props> = ({
  overrideRules,
  isCongratulationModal
}) => {
  const theme = useTheme();
  const isInFireshipSession = useCampaignSession(CampaignName.FIRESHIP_2025);
  const {
    campaign
  } = useCampaign(CampaignName.FIRESHIP_2025);
  const [ctaText, urlToNavigate] = useCampaignCta();
  const {
    pathname
  } = useLocation();
  const {
    data: userDataResponse
  } = useGetUserQuery();
  const shouldShow = useMemo(() => {
    const isPremium = userDataResponse?.data?.user?.isPremium;
    const isOnManageSub = checkMatch(['/manage-account/subscription'], pathname).doesMatch;
    const showBasedOnSubStatus = isPremium ? !isOnManageSub : true;
    return campaign && checkIfShouldShow(pathsToExclude, [], pathname) && showBasedOnSubStatus;
  }, [pathname, userDataResponse, campaign]);
  const url = userDataResponse?.data ? urlToNavigate : '/signup?source=fireship';
  const inPhase2 = campaign?.amount === 30;
  return (overrideRules || shouldShow) && isInFireshipSession ? <ThemeProvider theme={{
    ...theme,
    colors: lightColors
  }}>
      <StyledBar isCongratulationModal={isCongratulationModal}>
        <p>
          {inPhase2 ? <>
              Flash sale bonus! Fireship viewers now get <span>30% off</span>!
            </> : <>
              <span>Limited time offer:</span> Fireship Viewers Exclusive <span>20% off</span> annual subscriptions!
            </>}
        </p>
        <StyledButton as={Link} to={url} variant="solid" color="primary" size="small">
          {ctaText}
        </StyledButton>
        {inPhase2 && campaign && <StyledCountdown>
            <Countdown dark includeDays startTime={campaign.startTime} endTime={endTime.getTime()} />
          </StyledCountdown>}
      </StyledBar>
    </ThemeProvider> : null;
};