import { useCallback, useEffect } from 'react';

import { addSocketListener, emitSocketEvent, removeSocketListener, socket } from 'src/common/sockets';
import { useAppDispatch, useAppSelector } from 'src/app/hooks';
import * as TutorReducer from 'src/app/reducers/room/tutor.reducer';
import {
  TutorSocketEventType,
  SendTutorMessage,
  SendTutorMessageVote,
  GetQuestionHintData,
  ReadTutorMessages,
  SendAskEchoMessage,
} from 'src/features/room/components/tutor/tutor.types';

import { useTutorErrorHandling } from './use-tutor-error-handling';
import { useTutorMessages } from './use-tutor-messages';

interface UseTutorSocketProps {
  roomId?: string;
  roomCode: string;
}

export const useTutorSocketEvents = ({ roomId, roomCode }: UseTutorSocketProps) => {
  const {
    chat: { sessionId: tutorSessionId },
  } = useAppSelector((state) => state.tutor);

  const sendMessage = useCallback(
    (message: SendTutorMessage) => {
      if (tutorSessionId) {
        emitSocketEvent(TutorSocketEventType.SEND_MESSAGE, {
          tutorSessionId,
          roomId,
          ...message,
        });
      }
    },
    [roomId, tutorSessionId],
  );

  const readMessages = useCallback(
    (params?: { source?: string; sessionId?: string }) => {
      const { source = 'default', sessionId } = params ?? {};
      emitSocketEvent(TutorSocketEventType.READ_MESSAGES, {
        roomId,
        tutorSessionId: sessionId ?? tutorSessionId ?? undefined,
        source,
      });
    },
    [roomId, tutorSessionId],
  );

  const clearMessages = useCallback(() => {
    if (tutorSessionId) {
      emitSocketEvent(TutorSocketEventType.CLEAR_MESSAGES, { roomId, tutorSessionId });
    }
  }, [roomId, tutorSessionId]);

  const voteMessage = useCallback(
    (vote: SendTutorMessageVote) => {
      if (tutorSessionId) {
        emitSocketEvent(TutorSocketEventType.VOTE_MESSAGE, {
          ...vote,
          tutorSessionId,
        });
      }
    },
    [tutorSessionId],
  );

  const closeTermsOfUseBanner = useCallback(() => {
    emitSocketEvent(TutorSocketEventType.CLOSE_AI_TERMS_OF_USE_BANNER, {});
  }, []);

  const toggleNotifications = useCallback((state: boolean) => {
    emitSocketEvent(TutorSocketEventType.TOGGLE_NOTIFICATIONS, state);
  }, []);

  const updateDisplayMode = useCallback((mode: string) => {
    emitSocketEvent(TutorSocketEventType.UPDATE_DISPLAY_MODE, mode);
  }, []);

  const getQuestionHint = useCallback(
    ({ taskId, questionNo, roomType, roomDifficulty, tutorOpened }: GetQuestionHintData) => {
      emitSocketEvent(TutorSocketEventType.GET_QUESTION_HINT, {
        tutorSessionId: tutorSessionId ?? undefined,
        taskId,
        questionNo,
        roomCode,
        roomId,
        roomType,
        roomDifficulty,
        tutorOpened,
      });
    },
    [roomId, roomCode, tutorSessionId],
  );

  const sendAskEchoMessage = useCallback(
    (message: SendAskEchoMessage) => {
      emitSocketEvent(TutorSocketEventType.SEND_ASK_ECHO_MESSAGE, {
        tutorSessionId: tutorSessionId ?? undefined,
        roomId,
        message: message.message,
        additionalData: message.additionalData,
      });
    },
    [roomId, tutorSessionId],
  );

  return {
    sendMessage,
    readMessages,
    clearMessages,
    voteMessage,
    closeTermsOfUseBanner,
    toggleNotifications,
    updateDisplayMode,
    getQuestionHint,
    sendAskEchoMessage,
  };
};

export const useTutorSocketConnectionInit = ({ roomId, roomCode }: UseTutorSocketProps) => {
  const dispatch = useAppDispatch();
  const {
    chat: { sessionId: tutorSessionId },
    socketRooms,
  } = useAppSelector((state) => state.tutor);

  const { sendMessage, readMessages } = useTutorSocketEvents({ roomId, roomCode });
  const { updateMessagesWithDelay: setMessages } = useTutorMessages();
  const { handleError: handleSocketErrors } = useTutorErrorHandling({
    handleRetryableAction: (originalPayload) => {
      if (tutorSessionId && originalPayload) {
        sendMessage(originalPayload);
      } else {
        readMessages({ source: 'error-retry' });
      }
    },
    handleSocketReconnect: () => {
      emitSocketEvent('tutor-join', { roomId });
      dispatch(TutorReducer.joinSocketRoom(roomCode));
      readMessages({ source: 'error-reconnect' });
    },
  });

  useEffect(() => {
    const setIsLoading = (state: boolean) => {
      dispatch(TutorReducer.setIsLoading(state));
    };

    const setIsRestarting = (state: boolean) => {
      dispatch(TutorReducer.setIsRestarting(state));
    };

    const setIsChatDisabled = (state: boolean) => {
      dispatch(TutorReducer.setIsChatDisabled(state));
    };

    const setTutorSessionId = (sessionId: string) => {
      dispatch(TutorReducer.setTutorSessionId(sessionId));
    };

    const joinRoom = () => {
      const socketRoom = socketRooms.find((room) => room.roomCode === roomCode);

      if (socketRoom && socketRoom.joined && Date.now() - socketRoom.joinedAt < 1_800_000) {
        return;
      }
      emitSocketEvent('tutor-join', { roomId });
      dispatch(TutorReducer.joinSocketRoom(roomCode));
    };

    const handleReceiveMessage = (data: ReadTutorMessages) => {
      setTutorSessionId(data.tutorSessionId);
      setMessages(data.messages, () => {
        setIsLoading(false);
        setIsRestarting(false);
      });
      setIsChatDisabled(data.status === 'disabled');
    };

    const handleInvalidateButtons = () => {
      dispatch(TutorReducer.invalidateButtons());
    };

    const handleReconnect = () => {
      joinRoom();
    };

    const handleVisibilityChange = () => {
      if (!document.hidden && !socket.connected) {
        socket.connect();
        handleReconnect();
      }
    };

    joinRoom();
    addSocketListener('connect', joinRoom);
    addSocketListener('reconnect', handleReconnect);
    addSocketListener(TutorSocketEventType.RECEIVE_MESSAGE, handleReceiveMessage);
    addSocketListener(TutorSocketEventType.INVALIDATE_BUTTONS, handleInvalidateButtons);
    addSocketListener(TutorSocketEventType.RECEIVE_QUESTION_HINT, () => {});

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      removeSocketListener('connect');
      removeSocketListener('reconnect');
      removeSocketListener(TutorSocketEventType.RECEIVE_MESSAGE);
      removeSocketListener(TutorSocketEventType.INVALIDATE_BUTTONS);
      removeSocketListener(TutorSocketEventType.RECEIVE_QUESTION_HINT);

      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    addSocketListener(TutorSocketEventType.ERROR, handleSocketErrors);
    return () => {
      removeSocketListener(TutorSocketEventType.ERROR);
    };
  }, [handleSocketErrors]);
};
