import { matchPath, PathMatch } from 'react-router-dom';

import { CampaignName } from 'src/common/enums/campaigns';

import { CAMPAIGN_SESSION_STORAGE_KEY } from '../campaigns.types';

export const checkMatch = (paths: string[], pathname: string) => {
  let match: PathMatch | null = null;
  for (const path of paths) {
    match = matchPath(path, pathname);
    if (match) {
      break;
    }
  }

  return {
    doesMatch: !!match,
    isWideMatch: !!match?.params['*'],
  };
};

export const checkIfShouldShow = (pathsToExclude: string[], pathsToInclude: string[], pathname: string) => {
  const negativeMatch = checkMatch(pathsToExclude, pathname);
  const positiveMatch = checkMatch(pathsToInclude, pathname);

  if (!pathsToInclude.length && !negativeMatch.doesMatch) {
    return true;
  }

  if (!negativeMatch.doesMatch && !positiveMatch.doesMatch) {
    return false;
  }

  let show = false;
  if (negativeMatch.doesMatch && positiveMatch.doesMatch) {
    show = negativeMatch.isWideMatch || !positiveMatch.isWideMatch;
  } else if (positiveMatch.doesMatch) {
    show = true;
  }

  return show;
};

export const storeActiveCampaignSession = (name: CampaignName) => {
  sessionStorage.setItem(CAMPAIGN_SESSION_STORAGE_KEY, name);
};
