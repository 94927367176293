import styled from 'styled-components';

export const StyledCenteredContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyledContent = styled(StyledCenteredContent)`
  gap: 2.4rem;
  background: ${({ theme }) => `radial-gradient(
    300px 200px at 50% -5%,
    ${theme.colors.primary.main} -20%,
    ${theme.colors.secondary.main})`};

  padding: 4.6rem 1.8rem 3rem;
  @media screen and (min-width: ${({ theme }) => theme.sizes.md}) {
    padding: 4.6rem 4.6rem 3rem;
  }
`;

export const StyledHeading = styled.h2`
  font-weight: ${({ theme }) => theme.fonts.weight.bolder};
  color: ${({ theme }) => theme.colors.text.main};
  font-size: 2.4rem;
  margin-bottom: 1.6rem;
  text-align: center;

  @media screen and (min-width: ${({ theme }) => theme.sizes.md}) {
    font-size: 5rem;
  }
`;

export const StyledSubTitle = styled.h3`
  font-size: ${({ theme }) => theme.fonts.sizes.medium};
  font-weight: ${({ theme }) => theme.fonts.weight.semiBold};
  color: ${({ theme }) => theme.colors.text.main};
  margin-bottom: 0.6rem;
  text-align: center;

  span {
    color: ${({ theme }) => theme.colors.primary.main};
  }

  @media screen and (min-width: ${({ theme }) => theme.sizes.md}) {
    font-size: ${({ theme }) => theme.fonts.sizes.subTitle};
  }
`;

export const StyledText = styled.div`
  color: ${({ theme }) => theme.colors.text.main};
  font-size: ${({ theme }) => theme.fonts.sizes.default};
  text-align: center;

  span {
    color: ${({ theme }) => theme.colors.primary.main};
  }
`;

export const StyledButtons = styled.div`
  display: flex;
  gap: 1.6rem;
`;
