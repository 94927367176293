import { useViewport } from '@tryhackme/thm-ui-components';
import { useTheme } from 'styled-components';

export const useItemsPerPage = () => {
  const { width } = useViewport();
  const theme = useTheme();

  if (width >= Number.parseInt(theme.sizes.lg, 10)) return 4;
  if (width >= Number.parseInt(theme.sizes.md, 10)) return 3;
  return 1;
};
