import { createBrowserRouter, redirect, redirectDocument, RouterProvider as ReactRouterRouterProvider, LoaderFunctionArgs } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { SwagStoreURL } from 'src/common/constants';
import { Error500 } from 'src/features/error-pages';
import { Layout } from 'src/common/components/layout';
import { gb, gbPromise } from 'src/common/growthbook';
import { storeActiveCampaignSession } from 'src/features/campaigns/helpers';
import { CampaignName } from 'src/common/enums/campaigns';
import { routes } from './routes';
import { backToTheLegacyLoader, routeProtector } from './route-protector';
const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);
const router = sentryCreateBrowserRouter([{
  element: <Layout />,
  errorElement: <Error500 />,
  loader: routeProtector().waitForUserData(),
  children: [...routes, {
    path: '/swag-shop',
    loader: () => redirect(SwagStoreURL),
    element: null
  }, {
    path: '/work-at-thm',
    loader: () => redirect('https://careers.tryhackme.com'),
    element: null
  }, {
    path: '/fireship',
    loader: () => {
      storeActiveCampaignSession(CampaignName.FIRESHIP_2025);
      return redirect('/?utm_source=youtube&utm_medium=social&utm_campaign=fireship02042025');
    }
  }, {
    path: '*',
    loader: async ({
      request
    }: LoaderFunctionArgs) => {
      const url = new URL(request.url);
      // if (url.pathname.startsWith('/logout')) {
      //   await VerisoulClient.reinitialize();
      // }
      await gbPromise;
      if (gb.isOn('remove-slash-r-prefix')) {
        if (url.searchParams.get('clientRouterFallback') === 'true') {
          return redirectDocument('/not-found');
        }
        url.searchParams.set('clientRouterFallback', 'true');
        return redirectDocument(url.toString());
      }
      return backToTheLegacyLoader({
        request,
        params: {}
      });
    },
    element: null
  }]
}], {
  basename: process.env.PUBLIC_URL
});
export const RouterProvider = () => <ReactRouterRouterProvider router={router} data-sentry-element="ReactRouterRouterProvider" data-sentry-component="RouterProvider" data-sentry-source-file="index.tsx" />;