import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { ModalType } from 'src/common/enums';

export interface ModalStateType {
  currentModal: ModalType | null;
}

const initialState: ModalStateType = {
  currentModal: null,
};

const modalStatesSlice = createSlice({
  name: 'modalStates',
  initialState,
  reducers: {
    setCurrentModal: (state, action: PayloadAction<ModalType | null>) => ({
      ...state,
      currentModal: action.payload,
    }),
  },
});

export const { setCurrentModal } = modalStatesSlice.actions;

const modalStatesReducer = modalStatesSlice.reducer;
export { modalStatesReducer };
