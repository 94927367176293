import { FC, useEffect, useState } from 'react';
import { EventType, Fit, Layout, useRive } from '@rive-app/react-canvas';
import { ThemeProvider, useTheme } from 'styled-components';
import { darkColors, Modal, StyledButton } from '@tryhackme/thm-ui-components';
import { Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'src/app/hooks';
import { ModalType } from 'src/common/enums';
import { setCurrentModal } from 'src/app/reducers/modal-states.reducer';
import { CampaignName } from 'src/common/enums/campaigns';
import mysteryBoxOpen from 'src/images/pngs/campaigns/mystery-box-open.png';
import mysteryBoxSrc from 'src/images/animations/mystery-box.riv';
import { revealMysteryBox } from 'src/app/reducers/campaigns.reducer';
import { usePricing } from 'src/common/hooks';
import { useTrackEventMutation } from 'src/common/slices';
import { Countdown } from '../countdown';
import { useCampaign } from '../../hooks/use-campaign';
import { DiscountMessage } from '../campaign-modal/components/discount-message';
import { useCampaignStorage } from '../../hooks/use-campaign-storage';
import { useCampaignCta } from '../../hooks/use-campaign-cta';
import { StyledBoxImage, StyledBoxWrapper, StyledCongrats, StyledContent, StyledDiscountMessage, StyledFadeRoot, StyledHeading, StyledSubtitle } from './mystery-box-modal.styles';
export const MysteryBoxModal: FC = () => {
  const theme = useTheme();
  const {
    currentModal
  } = useAppSelector(state => state.modalStates);
  const revealed = useAppSelector(state => state.campaign.mysteryBoxRevealed);
  const dispatch = useAppDispatch();
  const [ctaText, urlToNavigate] = useCampaignCta();
  const {
    campaign
  } = useCampaign(CampaignName.FLASH_SALE_MYSTERY_DISCOUNT_BOX);
  const {
    markAsSeen
  } = useCampaignStorage(campaign);
  const [revealing, setRevealing] = useState(false);
  const [trackEvent] = useTrackEventMutation();
  const {
    price: priceStr,
    symbol
  } = usePricing(true);
  const price = Number.parseFloat(priceStr);
  const discountedPrice = price * (100 - (campaign?.amount ?? 0)) / 100;
  const isOpen = currentModal === ModalType.MYSTERY_BOX_CAMPAIGN;
  const onClose = () => {
    dispatch(setCurrentModal(null));
    markAsSeen();
  };
  const onCtaClick = () => {
    markAsSeen();
    dispatch(setCurrentModal(null));
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    trackEvent({
      event: 'click-subscribe-now',
      properties: {
        'clicked-on': 'dashboard-pop-up'
      }
    });
  };
  const {
    rive,
    RiveComponent
  } = useRive({
    src: mysteryBoxSrc,
    layout: new Layout({
      fit: Fit.FitHeight
    })
  });
  const reveal = () => {
    rive?.play();
    setRevealing(true);
    setTimeout(() => {
      setRevealing(false);
      dispatch(revealMysteryBox(true));
    }, 2000);
  };
  useEffect(() => {
    if (!rive) return;
    rive.on(EventType.Loop, () => {
      rive.scrub('Timeline 1', 2.99);
      rive.pause();
    });
  }, [rive, dispatch]);
  return campaign ? <ThemeProvider theme={{
    ...theme,
    colors: darkColors
  }}>
      <Modal padding="0" open={isOpen} maxHeight="100vh" onOpenChange={onClose} preventCloseOnOutsideClick>
        <StyledContent>
          <StyledFadeRoot $fade={revealing}>
            {revealed ? <StyledCongrats>
                Congratulations! You&apos;ve unlocked a <span>{campaign.amount}% discount</span> on your annual
                subscription!
              </StyledCongrats> : <>
                <StyledHeading>WHAT’S IN THE BOX?</StyledHeading>
                <StyledSubtitle>Open it now to reveal your mystery discount!</StyledSubtitle>
              </>}
            <Countdown includeDays vertical dark startTime={campaign.startTime} endTime={campaign.endTime} />
          </StyledFadeRoot>
          <StyledBoxWrapper>{revealed ? <StyledBoxImage src={mysteryBoxOpen} /> : <RiveComponent />}</StyledBoxWrapper>
          <StyledFadeRoot $fade={revealing}>
            {revealed && <StyledDiscountMessage>
                <DiscountMessage price={price} discountedPrice={discountedPrice} symbol={symbol} />
              </StyledDiscountMessage>}

            {revealed ? <StyledButton as={Link} role="button" to={urlToNavigate} aria-label="Subscribe now" variant="solid" color="primary" onClick={onCtaClick}>
                {ctaText}
              </StyledButton> : <StyledButton variant="solid" color="primary" onClick={reveal}>
                Reveal discount
              </StyledButton>}
          </StyledFadeRoot>
        </StyledContent>
      </Modal>
    </ThemeProvider> : null;
};