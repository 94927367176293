export enum ApiResponseStatus {
  SUCCESS = 'success',
  FAIL = 'fail',
  ERROR = 'error',
}

export enum ThemeColorMode {
  DARK = 'dark',
  LIGHT = 'light',
}

// https://www.notion.so/tryhackme/User-Roles-and-Permissions-8002db7803e84808a05dd68c117d3cc5
export enum UserRoles {
  EDITOR = 'editor',
  TESTER = 'tester',
  BETA_TESTER = 'beta-tester',
  ROOM_TESTER = 'room-tester',
  MOD = 'mod',
  ROOM_MAKER = 'room-maker',
  // @TODO: Refactor - remove content-dev - https://tryhackme.atlassian.net/browse/PLAT-540
  CONTENT_DEV = 'content-dev',
  CONTENT_DEV_INTERNAL = 'content-dev-internal',
  CONTENT_DEV_EXTERNAL = 'content-dev-external',
  BDM = 'bdm',
  GLOSSARY_EDITOR = 'glossary-editor',
  SDR = 'sdr',
  ANALYST = 'analyst',
  TAG_ADMIN = 'tag-admin',
  AZURE_ADMIN = 'azure-admin',
  SCRIPT_RUNNER = 'script-runner',
  CATEGORIZATION_RULE_DEV = 'categorization-rule-dev',
  SOC_SIM_INTERNAL_QA = 'soc-sim-internal-qa',
}

export enum UserAccountTypes {
  ADMIN = 'admin',
  REGULAR = 'regular',
  TEACHER = 'teacher',
}

export enum UserLoginTypes {
  LOCAL = 'local',
  GOOGLE = 'google',
  SINGLE_SIGN_ON = 'sso',
}

export enum RoomDetailsStatusCodes {
  OK = 0,
  BANNED = 1,
  LOCKED = 2,
  COMING_SOON = 3,
  BUSINESS_ONLY = 4,
  SUBSCRIBER_ONLY = 5,
  REQUIRES_CLOUD_AWS_ACTIVE = 6,
  REQUIRES_CLOUD_AZURE_ACTIVE = 7,
}

export enum NavLinkOptionNames {
  SHOW_CTF_BUILDER = 'SHOW_CTF_BUILDER',
  SHOW_MGT_DASHBOARD = 'SHOW_MGT_DASHBOARD',
}

export enum AdditionalContentTypes {
  AWS = 'aws',
  AZURE = 'azure',
}

export enum AWSAccountState {
  IN_USE = 'in_use',
  ENV_GEN = 'env_gen',
  ASSIGNING = 'assigning',
  RESETTING = 'resetting',
  QUARANTINED = 'quarantined',
  QUARANTINING = 'quarantining',
  RECYCLED = 'recycled',
}

export enum HttpStatus {
  CONTINUE = 100,
  SWITCHING_PROTOCOLS = 101,
  PROCESSING = 102,
  EARLYHINTS = 103,
  OK = 200,
  CREATED = 201,
  ACCEPTED = 202,
  NON_AUTHORITATIVE_INFORMATION = 203,
  NO_CONTENT = 204,
  RESET_CONTENT = 205,
  PARTIAL_CONTENT = 206,
  AMBIGUOUS = 300,
  MOVED_PERMANENTLY = 301,
  FOUND = 302,
  SEE_OTHER = 303,
  NOT_MODIFIED = 304,
  TEMPORARY_REDIRECT = 307,
  PERMANENT_REDIRECT = 308,
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  PAYMENT_REQUIRED = 402,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  METHOD_NOT_ALLOWED = 405,
  NOT_ACCEPTABLE = 406,
  PROXY_AUTHENTICATION_REQUIRED = 407,
  REQUEST_TIMEOUT = 408,
  CONFLICT = 409,
  GONE = 410,
  LENGTH_REQUIRED = 411,
  PRECONDITION_FAILED = 412,
  PAYLOAD_TOO_LARGE = 413,
  URI_TOO_LONG = 414,
  UNSUPPORTED_MEDIA_TYPE = 415,
  REQUESTED_RANGE_NOT_SATISFIABLE = 416,
  EXPECTATION_FAILED = 417,
  I_AM_A_TEAPOT = 418,
  MISDIRECTED = 421,
  UNPROCESSABLE_ENTITY = 422,
  FAILED_DEPENDENCY = 424,
  PRECONDITION_REQUIRED = 428,
  TOO_MANY_REQUESTS = 429,
  INTERNAL_SERVER_ERROR = 500,
  NOT_IMPLEMENTED = 501,
  BAD_GATEWAY = 502,
  SERVICE_UNAVAILABLE = 503,
  GATEWAY_TIMEOUT = 504,
  HTTP_VERSION_NOT_SUPPORTED = 505,
}

export enum AWSAccessSources {
  B2B = 'b2b',
  ADDON = 'addon',
}

export enum CurrencySymbol {
  USD = '$',
  GBP = '£',
}

export enum UserHowHeard {
  FRIENDS_FAMILY = 'friends_family',
  WORK_COLLEAGUE = 'work_colleague',
  TEACHER_UNIVERSITY = 'teacher_university',
  YOUTUBE = 'youtube',
  REDDIT_FORUMS = 'reddit_forums',
  SOCIAL_MEDIA = 'social_media',
  GOOGLE_BING_SEARCH = 'google_bing_search',
  NEWS_BLOG = 'news_blog',
  USED_IT_BEFORE = 'used_it_before',
  OTHER = 'other',
}

export enum UserField {
  DEFENSIVE = 'defensive',
  DEVSECOPS = 'devsecops',
  PENTESTING = 'pentesting',
  RED_TEAMING = 'red-teaming',
  SECURITY_ENGINEERING = 'security-engineering',
  SOC = 'soc',
  OTHER = 'other',
  NOT_SURE = 'not-sure',
}

export enum UserExperienceCyber {
  COMPLETELY_NEW = 'completely-new',
  SOME_KNOWLEDGE = 'some-knowledge',
  PRACTICAL_EXPERIENCE = 'practical-experience',
}

export enum UserExperienceCyberDetail {
  NEW_TO_IT = 'new-to-it',
  FAMILIAR_WITH_IT = 'familiar-with-it',
  NOT_WORKED_YET = 'not-worked-yet',
  LESS_TWO_YEARS = 'less-2-years',
  MORE_TWO_YEARS = 'more-2-years',
}

export enum UserYearsInCyber {
  NOT_WORKED_YET = 'not-worked-yet',
  LESS_TWO_YEARS = 'less-2-years',
  MORE_TWO_YEARS = 'more-2-years',
}

export enum UserHasFieldPreference {
  NOT_YET = 'not-yet',
  YES = 'yes',
}

export enum UserComputerScienceBasics {
  FAMILIAR = 'familiar',
  NEW_TO = 'new-to',
}

export enum UserComputerScienceBasicsGetStarted {
  GAIN_FUNDAMENTAL_SKILLS = 'gain-fundamental-skills',
  UPSKILL_FIELD = 'upskill-field',
}

export enum UserGoal {
  GET_JOB_CYBER = 'get-job-cyber',
  SWITCH_CAREERS = 'switch-careers',
  GET_PROMOTION = 'get-promotion',
  PREPARE_CERTIFICATION = 'prepare-certification',
  MASTER_NEW_SKILL = 'master-new-skill',
  LEARN_SCHOOL = 'learn-school',
  JUST_LOOKING_AROUND = 'just-looking-around',
  TRAIN_TEAM = 'train-team',
  UPSKILL_FIELD = 'upskill-field',
  GAIN_FUNDAMENTAL_SKILLS = 'gain-fundamental-skills',
  EVALUTE_THM = 'evaluate-THM',
}

/**
 * @see From the WorkOS SDK types for {@link https://github.com/workos/workos-node/blob/main/src/portal/interfaces/generate-portal-link-intent.interface.ts generate-portal-link-intent}
 */
export enum GeneratePortalLinkIntent {
  AuditLogs = 'audit_logs',
  DomainVerification = 'domain_verification',
  DSync = 'dsync',
  LogStreams = 'log_streams',
  SSO = 'sso',
}

/**
 * @see From the WorkOS SDK types for {@link https://github.com/workos/workos-node/blob/main/src/organization-domains/interfaces/organization-domain.interface.ts organization-domain}
 */
export enum OrganizationDomainState {
  /**
   * Deprecated by  WorkOS, but needed for appropriate UI behaviour
   */
  LegacyVerified = 'legacy_verified',
  Verified = 'verified',
  Pending = 'pending',
  Failed = 'failed',
}

export enum ChargebeeSubscriptionStatus {
  ACTIVE = 'active',
  NON_RENEWING = 'non_renewing',
  CANCELLED = 'cancelled',
  IN_TRIAL = 'in_trial',
}

export enum UserOnboardingSegment {
  SEGMENT_1 = 'segment-1',
  SEGMENT_2 = 'segment-2',
  'SEGMENT_2.5' = 'segment-2.5',
  SEGMENT_3 = 'segment-3',
  SEGMENT_4 = 'segment-4',
}

export enum ModalType {
  RESTRICTED_SERVICE = 'RESTRICTED_SERVICE',
  CERTIFICATION_RELEASE = 'CERTIFICATION_RELEASE',
  MYSTERY_BOX_CAMPAIGN = 'MYSTERY_BOX_CAMPAIGN',
  FIRESHIP_CAMPAIGN = 'FIRESHIP_CAMPAIGN',
}
